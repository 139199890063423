<template>
    <v-card>
        <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
        >Success</v-alert>
        <v-form
        ref="form"
        lazy-validation
        >
        <v-card-title
            class="px-4"
        >
            <span class="headline">день закрытия</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            class="px-4"
        >
        <template>
            <v-data-table
                :headers="headersTable"
                :items="editedItem"
                hide-actions
                no-data-text="Записей не найдено"
                no-results-text="Записей не найдено"
            >
                <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>

                <td>{{ props.item.sum }}</td>

                <td>{{ props.item.sum }}</td>

                <td>
                    <v-text-field
                    type="number"
                    ></v-text-field>
                </td>

                </template>
            </v-data-table>
        </template>
        </v-card-text>
        <v-card-actions
            class="px-4 pb-4"
        >
            <v-btn
             @click.native="cancel()"
            >Отмена</v-btn>
            <v-spacer></v-spacer>
            <v-btn
            color="info"
            @click="submitForm"
            >Сохранить</v-btn>
        </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import axios from 'axios';
export default {
  props: {
    item: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
        createdSuccess: false,
        editedItem :[],
        headersTable: [
        {
          text: 'Дата',
          align: 'left',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Amount',
          align: 'left',
          value: 'sum',
          sortable: false,
        },
        {
          text: 'Bill',
          align: 'left',
          value: 'bill',
          sortable: false,
        },
        {
          text: 'Recieved',
          align: 'left',
          value: 'recieved',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    cancel() {
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
        const validate = this.$refs.form.validate();
    },
    getItem() {
        // remove balnace type, and sum is 0
        console.log(this.item)
        this.editedItem = this.item.filter(item => (item.sum !== 0));
    },
  },
  mounted() {
    this.getItem();
  },

}
</script>