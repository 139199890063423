<template>
  <v-container fluid class="pa-0">
    <v-snackbar
      :value="true"
      v-if="checkSum > 0"
      color="info"
      bottom
      :timeout="3600000"
    >
      Общая стоимость: {{ checkSum }}
      <v-btn color="error" depressed @click="deleteCheckCards">Удалить</v-btn>
      <v-btn color="info" depressed @click="dialogPay = true">Оплатить</v-btn>
    </v-snackbar>
    <v-dialog :value="loadingDialog" persistent max-width="320px">
      <v-list>
        <v-list-tile
          v-for="(item, index) in loadingData"
          :key="index"
          avatar
          :color="item.error ? 'red' : item.color"
        >
          <v-list-tile-avatar>
            <v-progress-circular
              :value="100"
              :size="30"
              :color="item.error ? 'red' : item.color"
              :indeterminate="item.loading"
            ></v-progress-circular>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-dialog>

    <payment-day
      v-if="!loadingDialog"
      class="print-hidden"
      :upadate="upadatePayments"
      :paymentTypesList="paymentTypesList"
      @updateComplete="upadatePayments = false"
      @upadatePaymentsSum="upadatePaymentsSum()"
      @statPayBlockHeight="updateTopHomeHeight"
    ></payment-day>

    <v-layout ref="topHome" row wrap v-if="!loadingDialog" class="print-hidden top-home">
      <v-flex xs4>
        <v-card flat v-for="(item, index) in propsBouquet" :key="index">
          <v-card flat v-if="item !== ''">
            <div
              class="py-1 px-3 text-xs-right"
              :style="'height: ' + (!!item ? 30 : 40) + 'px;'"
            >
              {{ item }}
            </div>
            <v-divider v-if="index !== propsBouquet.length - 1"></v-divider>
          </v-card>
           <!-- Render v-select component if item is an empty string -->
           <v-card flat v-else style="width: 50%; float: right;">
            <div
              class="py-1 px-3 text-xs-right"
            >
            <v-select
              v-if="shouldDisplayStoreSelect"
              label="Магазин"
              :items="storeList"
              item-text="name"
              item-value="id"
              v-model="store_id"
              hide-details
              clearable
              no-data-text="Не найдено"
              @change="handleStoreChange"
            ></v-select>
            </div>
            <v-divider></v-divider>
          </v-card>
        </v-card>
        
      </v-flex>
      <v-flex xs8>
        <v-container
          id="scroll-block-top"
          style="overflow: auto;"
          class="scroll-y pa-0 right-block"
        >
          <v-layout row v-scroll:#scroll-block-top="onScroll">
            <template v-for="(item, index) in cardsList">
              <v-flex :key="index" v-if="!item.success">
                <created-bouquet-card
                  :florists-list="floristsList"
                  :store-list="storeList"
                  :adminRole="adminRole"
                  :sumFlowers="item.sum"
                  :propsDefault="item.props"
                  :goods="item.goods"
                  :store_id = "store_id"
                  @saveProps="saveProps(index, $event)"
                  @updateProps="updateProps(index, $event)"
                  @copy="copyItem(index)"
                  @delete="deleteItem(index)"
                  @checkCard="checkCard(index, $event)"
                  ref="createBouquetCard"
                ></created-bouquet-card>
              </v-flex>
            </template>
            <v-flex style="align-self: center;">
              <v-btn
                fab
                large
                color="info"
                class="mx-4"
                @click="addCard"
                title="Добавить букет"
              >
                <v-icon dark>add</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
    <!-- goods list -->
    <v-layout
      row
      wrap
      style="min-height: 100px; height: 528px; overflow-y: scroll;"
      v-if="!loadingDialog"
      class="print-hidden"
    >
      <v-flex xs4>
        <v-layout row wrap>
          <v-flex>
            <v-card flat>
              <div class="py-1 px-1 text-xs-center" style="height: 30px;">
                Остаток
              </div>
              <v-divider></v-divider>
              <template v-for="(item, index) in goods">
                <template v-if="showGoodsList.indexOf(item.id) !== -1">
                  <div
                    class="py-1 px-1 text-xs-center"
                    style="height: 30px;"
                    :key="index"
                  >
                    {{ item.stockBalance }}
                  </div>
                  <v-divider :key="'divider-' + index"></v-divider>
                </template>
              </template>
            </v-card>
          </v-flex>
          <v-flex>
            <v-card flat>
              <div class="py-1 px-1 text-xs-center" style="height: 30px;">
                Наименование
              </div>
              <v-divider></v-divider>
              <template v-for="(item, index) in goodsList">
                <template v-if="showGoodsList.indexOf(item.id) !== -1">
                  <div
                    class="py-1 px-1 text-xs-center"
                    :style="
                      item.color
                        ? `background-color: ${item.color}; height: 30px;`
                        : 'height: 30px;'
                    "
                    :key="index"
                  >
                    {{ item.name }}
                  </div>
                  <v-divider :key="'divider-' + index"></v-divider>
                </template>
              </template>
            </v-card>
          </v-flex>
          <v-flex>
            <v-card flat>
              <div class="py-1 px-1 text-xs-center" style="height: 30px;">
                Цена
                <v-icon left @click="printPage()" title="Печать прайс-листа"
                  >print</v-icon
                >
              </div>
              <v-divider></v-divider>
              <template v-for="(item, index) in goodsList">
                <template v-if="showGoodsList.indexOf(item.id) !== -1">
                  <div
                    class="py-1 px-1 text-xs-center"
                    style="height: 30px;"
                    :key="index"
                  >
                    {{ item.price }}
                  </div>
                  <v-divider :key="'divider-' + index"></v-divider>
                </template>
              </template>
            </v-card>
          </v-flex>
          <v-flex>
            <v-card flat>
              <div class="py-1 px-1 text-xs-center" style="height: 30px;">
                магазин
              </div>
              <v-divider></v-divider>
              <template v-for="(item, index) in goodsList">
                <template v-if="showGoodsList.indexOf(item.id) !== -1">
                  <div
                    class="py-1 px-1 text-xs-center"
                    style="height: 30px;"
                    :key="index"
                  >
                    {{ item.store.name }}
                  </div>
                  <v-divider :key="'divider-' + index"></v-divider>
                </template>
              </template>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs8 style="padding-left: 6px;">
        <v-container
          id="scroll-block-bottom"
          style="overflow: hidden;"
          class="scroll-y pa-0 right-block"
        >
          <v-layout row>
            <template v-for="(item, index) in cardsList">
              <v-flex :key="index" v-if="!item.success">
                <select-count-goods
                  :goods-list="goodsList"
                  :store_id="store_id"
                  :selected-goods-default="item.goods"
                  @changeSum="setSum(index, $event)"
                  @changeGoods="setGoods(index, $event)"
                  :key="index"
                ></select-count-goods>
              </v-flex>
            </template>
            <v-flex>
              <div style="width: 120px;"></div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogPay" persistent max-width="420px">
      <bouquet-multi-payment
        :items="checkCardListObj"
        :store_id = "store_id"
        @cancel="dialogPay = false"
        @saveProps="saveItemsProps($event)"
      ></bouquet-multi-payment>
    </v-dialog>

    <div class="report print-visible">
      <table>
        <tr>
          <td>Остаток</td>
          <td>Наименование</td>
          <td>Цена</td>
        </tr>
        <template v-for="(item, index) in priceList">
          <tr :key="index">
            <td>{{ item.stockBalance }}</td>
            <td
              :style="item.color ? `background-color: ${item.color};` : ''"
            >{{ item.name }}</td>
            <td>{{ item.price }}</td>
          </tr>
        </template>
      </table>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import CreatedBouquetCard from '../components/CreatedBouquetCard.vue';
import BouquetMultiPayment from '../components/BouquetMultiPayment.vue';
import SelectCountGoods from '../components/SelectCountGoods.vue';
import PaymentDay from './Pays/paymentDay.vue';
import gql from 'graphql-tag';
import { PaymentTypes } from '@/constants';

export default {
  name: 'Home',
  components: {
    'created-bouquet-card': CreatedBouquetCard,
    'select-count-goods': SelectCountGoods,
    PaymentDay,
    BouquetMultiPayment,
  },
  data() {
    return {
      dateNow: '',
      dateYesterday: '',
      loadingData: [
        {
          title: 'Получение флористов',
          error: false,
          loading: true,
          color: 'cyan',
          id: 'florists',
        },
        {
          title: 'Получение товаров',
          error: false,
          loading: true,
          color: 'blue-grey',
          id: 'goods',
        },
        {
          title: 'Получение типов оплат',
          error: false,
          loading: true,
          color: 'blue-grey',
          id: 'payment-types',
        },
      ],
      offsetLeft: 0,
      propsBouquet: [
        'Флорист',
        'Клиент',
        'Заказ | Букет',
        'Cтавка на оформление, % | Предоплата',
        'Сумма товара',
        'Доставка | Комментарий',
        'Оформление',
        'Ставка на скидку, % | Сумма скидки',
        'Всего к оплате | Кол-во',
        '',
      ],
      store_id: 1,
      adminRole:'',
      cardsList: [],
      floristsList: [],
      paymentTypesList: [],
      goodsList: [],
      checkCardList: [],
      createdSuccess: false,
      dialogPay: false,
      sumClient: 0,
      secondSumClient: 0,
      typePay: null,
      upadatePayments: false,
      storeList: [],
      statPayBlockHeight: 0,
    };
  },
  computed: {
    priceList() {
      //const findIndex = this.goodsList.findIndex(item => item.name === 'Амбрелла');
      //const priceList = this.goodsList.slice(0, findIndex + 1);
      //return priceList;
      return this.goodsList;
    },
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    goods() {
      for (const good of this.goodsList) {
        good.stockBalance = good.stock;
      }

      for (const good of this.goodsList) {
        const goodCardsSum = this.cardsList.reduce((acc, card) => {
          const { value } = card.goods.find(g => g.id === good.id) || {
            value: 0,
          };

          return acc + value;
        }, 0);

        good.stockBalance -= goodCardsSum;
      }

      return this.goodsList;
    },
    typePayList() {
      return this.paymentTypesList.filter((item) => {
        if (this.client) {
          return item.id !== 5;
        }

        return item.id !== 7;
      });
    },
    sumChange: function sumChange() {
      const sum = this.sumClient - this.checkSum;
      return sum > 0 ? sum : 0;
    },
    checkSum() {
      return this.checkCardList.reduce((sum, item) => {
        const allSum = sum + item.sum;
        return allSum;
      }, 0);
    },
    loadingDialog: function loadingDialog() {
      const loadData = this.loadingData.filter(item => !item.error && !item.loading);
      return loadData.length === this.loadingData.length ? 0 : 1;
    },
    showGoodsList() {
      const showGoodsList = this.$store.getters.getShowGoodsList;
      let goodsList = this.goodsList.map(item => item.id);
      if (showGoodsList.length > 0) {
        goodsList = showGoodsList;
      }
      return goodsList;
    },

    checkCardListObj() {
      return this.checkCardList.map(item => this.cardsList[item.index]);
    },
  },
  methods: {
    updateTopHomeHeight(height) {
      this.statPayBlockHeight = height;
      this.$nextTick(() => {
        const topHome = this.$refs.topHome;

        if (topHome) {
          topHome.style.height = `${this.statPayBlockHeight + 3}px`;
        }
      });
    },
    handleStoreChange() {
      if (this.store_id) {
        this.getGoodsList();
      }
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGoodsList() {
      const loadData = this.loadingData.find(item => item.id === 'goods');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/goods`;
      axios
        .get(url, { params: { store_id: this.store_id } })
        .then((response) => {
          this.goodsList = response.data;
          
          this.$emit('goods-updated', this.goodsList);
          loadData.title = 'Товары получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения товаров!';
          loadData.error = true;
          console.log(error);
        });
    },
    getPaymentTypesList() {
      const loadData = this.loadingData.find(item => item.id === 'payment-types');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;

      axios
        .get(url)
        .then((response) => {
          const items = response.data;
          this.paymentTypesList = items;
          loadData.title = 'Типы оплат получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения типов оплат!';
          loadData.error = true;
          console.log(error);
        });
    },
    getFloristsList() {
      const loadData = this.loadingData.find(item => item.id === 'florists');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: {
            group_id: 16,
          },
        })
        .then((response) => {
          const items = response.data;
          this.floristsList = items;

          loadData.title = 'Флористы получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения флористов!';
          loadData.error = true;
          console.log(error);
        });
    },
    handleLoadingSuccess(loadingBarId, msg) {
      const loadData = this.loadingData.find(item => item.id === loadingBarId);
      loadData.title = msg;
      loadData.loading = false;
    },
    handleLoadingFailed(loadingBarId, msg) {
      const loadData = this.loadingData.find(item => item.id === loadingBarId);
      loadData.title = msg;
      loadData.error = true;
    },
    onScroll: function onScroll(e) {
      const scroll = e.target.scrollLeft;

      if (e.target.id === 'scroll-block-top') {
        document.getElementById('scroll-block-bottom').scrollLeft = scroll;
      } else {
        // document.getElementById('scroll-block-top').scrollLeft = scroll;
      }
    },
    setSum: function setSum(index, sum) {
      const item = this.cardsList[index];
      item.sum = sum;
      this.$set(this.cardsList, index, item);
    },
    setGoods: function setSum(index, goods) {
      const item = this.cardsList[index];
      item.goods = goods;
      this.$set(this.cardsList, index, item);

      const cardNoEmpty = this.cardsList.filter(elem =>
        (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
          elem.success !== true);
      localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));
    },
    addCard: function addCard() {
      this.cardsList.push({
        sum: 0,
        success: false,
        props: {
          floristId: 0,
          totalCost: 0,
          decorPercent: 25,
          decorCost: 0,
          deliveryCost: 0,
          salePercent: 0,
          sumSale: 0,
          payment: {
            paymentTypeId: 1,
            amount: 0,
            clientId: null,
            description: '',
          },
          prePayment: 0,
          comment: '',
          sumDecorAdditional: 0,
          bouquetCount: 1,
          clientId: null,
          orderBouquet: null,
          orderId: null,
        },
        goods: [],
      });
    },
    checkCard(indexCard, sumPay) {
      const findIndex = this.checkCardList.findIndex(item => item.index === indexCard);

      if (findIndex + 1) {
        this.checkCardList.splice(findIndex, 1);
      } else {
        this.checkCardList.push({
          index: indexCard,
          sum: sumPay,
        });
      }
    },
    saveItemsProps(props) {
      this.upadatePayments = true;

      this.checkCardList.forEach((item) => {
        const cardItem = this.cardsList[item.index];
        cardItem.success = true;
        this.$set(this.cardsList, item.index, cardItem);

        const cardNoEmpty = this.cardsList.filter(elem =>
          (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
            elem.success !== true);
        localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));
      });

      this.checkCardList = [];
    },
    upadatePaymentsSum() {
      this.upadatePayments = true;
    },
    saveProps: function saveProps(index, props) {
      this.upadatePayments = true;

      const cardItem = this.cardsList[index];
      cardItem.success = true;
      this.$set(this.cardsList, index, cardItem);

      const cardNoEmpty = this.cardsList.filter(elem =>
        (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
          elem.success !== true);
      localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));

      const findIndex = this.checkCardList.findIndex(card => card.index === index);

      if (findIndex + 1) {
        this.checkCardList.splice(findIndex, 1);
      }
      // const arSuccess = [];

      // if (props) {
      //   let emptySum = true;

      //   if (cardItem.goods.length > 0) {
      //     emptySum = false;
      //   }

      //   const goods = cardItem.goods.map(item => ({
      //     count: item.value,
      //     goodId: item.id,
      //   }));

      //   props.payment.clientId = (props.payment.clientId) ? props.payment.clientId : 0;

      //   const propsService = {
      //     clientID: props.payment.clientId,
      //     userID: this.$store.getters.getAuthUser,
      //     payments: [
      //       {
      //         paymentTypeId: props.payment.paymentTypeId,
      //         price: +props.payment.paymentTypeId === PaymentTypes.BALANCE
      //           ? -(props.payment.amount / props.bouquetCount)
      //           : props.payment.amount / props.bouquetCount,
      //         comment: props.payment.description,
      //       },
      //     ],
      //   };

      //   if (props.floristId) {
      //     propsService.floristID = props.floristId;
      //   }

      //   if (props.secondPayment) {
      //     propsService.payments.push({
      //       paymentTypeId: props.secondPayment.paymentTypeId,
      //       price: +props.secondPayment.paymentTypeId === PaymentTypes.BALANCE
      //         ? -(props.secondPayment.amount / props.bouquetCount)
      //         : props.secondPayment.amount / props.bouquetCount,
      //       comment: props.secondPayment.description,
      //     });
      //   }

      //   if (emptySum && (props.decorCost || props.sumDecorAdditional || props.deliveryCost)) {
      //     propsService.decorPrice = (props.decorCost) ? props.decorCost : null;
      //     propsService.additionalDecorPrice = (props.sumDecorAdditional) ? props.sumDecorAdditional : null;
      //     propsService.deliveryPrice = (props.deliveryCost) ? props.deliveryCost : null;
      //   } else if (!emptySum) {
      //     propsService.decorPrice = props.decorCost;
      //     propsService.deliveryPrice = props.deliveryCost;
      //     propsService.additionalDecorPrice = props.sumDecorAdditional;
      //     propsService.decorPercent = props.decorPercent;
      //     propsService.deliveryComment = props.comment;
      //     propsService.salePercent = props.salePercent;
      //     propsService.salePrice = props.sumSale;
      //     propsService.serviceGoods = goods;
      //     propsService.totalCost = props.totalCost;
      //     propsService.orderBouquetID = props.orderBouquet;
      //   }

      //   for (let i = 0; i < props.bouquetCount; i++) {
      //     this.$apollo.mutate({
      //       mutation: gql`mutation createService (
      //         $props: NewService!
      //       ) {
      //         createService(input: $props) {
      //           id
      //         }
      //       }`,
      //       variables: {
      //         props: propsService,
      //       },
      //     }).then(() => {
      //       arSuccess.push(true);

      //       if (arSuccess.length === props.bouquetCount) {
      //         cardItem.success = true;
      //         this.$set(this.cardsList, index, cardItem);

      //         this.refreshPayments();

      //         const cardNoEmpty = this.cardsList.filter(elem =>
      //           (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
      //             elem.success !== true);
      //         localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));

      //         const findIndex = this.checkCardList.findIndex(card => card.index === index);

      //         if (findIndex + 1) {
      //           this.checkCardList.splice(findIndex, 1);
      //         }
      //       }
      //     }).catch((error) => {
      //       console.error(error);
      //     });
      //   }
      // }
    },
    updateProps: function updateProps(index, props) {
      const item = this.cardsList[index];
      item.props = props;
      this.$set(this.cardsList, index, item);

      const cardNoEmpty = this.cardsList.filter(elem =>
        (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
          elem.success !== true);
      localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));
    },
    copyItem(index) {
      const item = Object.assign({}, this.cardsList[index]);
      this.cardsList.push(item);
      const cardNoEmpty = this.cardsList.filter(elem =>
        (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
          elem.success !== true);
      localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));
    },
    deleteItem(index) {
      const item = this.cardsList[index];
      item.success = true;

      const cardNoEmpty = this.cardsList.filter(elem =>
        (elem.goods.length > 0 || Object.keys(elem.props).length > 0) &&
          elem.success !== true);
      localStorage.setItem('cardsList', JSON.stringify(cardNoEmpty));

      const findIndex = this.checkCardList.findIndex(card => card.index === index);

      if (findIndex + 1) {
        this.checkCardList.splice(findIndex, 1);
      }
    },
    pay() {
      this.createdSuccess = true;
      const checkCards = this.checkCardList.slice();

      checkCards.forEach((item) => {
        const card = this.cardsList[item.index];

        let sumPay = card.sum;
        sumPay += +card.props.decorCost;
        sumPay += +card.props.deliveryCost;
        sumPay -= +card.props.sumSale;

        card.props.payment = {
          paymentTypeId: this.typePay,
          amount: sumPay,
          clientId: card.props.clientId,
          description: '',
        };

        card.props.typePay = this.typePay;
        this.saveProps(item.index, card.props);
      });

      setTimeout(() => {
        this.dialogPay = false;
      }, 1000);
    },
    deleteCheckCards() {
      const checkCards = this.checkCardList.slice();

      checkCards.forEach((item) => {
        this.deleteItem(item.index);
      });
    },
    getErrors(rawErrors) {
      return rawErrors.reduce((acc, msg) => [...acc, ...Object.values(msg.constraints)], []);
    },
    printPage() {
      window.print();
    },
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole

    this.getGoodsList();
    this.getFloristsList();
    this.getPaymentTypesList();
    this.getStoreList();
    
    this.$store.commit('setShowGoodsList', []);

    const dateNow = new Date();
    const dateNowStr = dateNow.toISOString().split('T')[0];
    this.dateNow = `${dateNowStr} 23:59:59`;
    dateNow.setDate(dateNow.getDate() - 1);
    const dateYesterdayStr = dateNow.toISOString().split('T')[0];
    this.dateYesterday = `${dateYesterdayStr} 00:00:00`;

    const cardsList = JSON.parse(localStorage.getItem('cardsList'));
    this.cardsList = cardsList !== null ? cardsList : [];
    const addCountElem = 1 - this.cardsList.length;

    for (let i = 0; i < addCountElem; i += 1) {
      this.addCard();
    }

    this.$emit('store-list-updated', this.storeList);
  },
};
</script>

<style lang="scss">
.scs-small {
  &.v-text-field {
    .v-input__control {
      min-height: 30px;
    }
    input {
      max-height: 30px;
      padding: 5px 0;
    }
    .v-select__selection--comma {
      margin: 6px 4px 6px 0;
      white-space: nowrap;
      overflow: hidden;
      max-width: 116px;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.input-min {
  width: 40px;
  display: inline-block;

  .v-text-field.v-text-field--enclosed {
    .v-input__slot {
      padding: 0 5px;
    }
  }
}

.v-card.selected {
  box-shadow: inset 0 0 2px 2px #008a00;
}

.v-btn--small {
  min-width: 50px;
}

.right-block {
  .flex {
    max-width: 300px;
  }
}

.view-filter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding-bottom: 5px;

  &:hover,
  &:focus {
    border-bottom-color: #000;
  }

  #autosuggest__input {
    text-indent: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
