<template>
  <v-card>
    <v-alert :value="createdSuccess" type="success" class="my-0">
      Букеты созданы
    </v-alert>

    <v-form ref="form" lazy-validation>
      <v-card-title class="px-4">
        <span class="headline">Оплата заказа</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4">
        <v-text-field
          label="Сумма заказа"
          readonly
          :value="sumOrders"
        ></v-text-field>

        <v-text-field
          label="К оплате"
          readonly
          :value="sumOrdersPay"
        ></v-text-field>

        <v-text-field
          label="Предоплата"
          readonly
          :value="sumPrePaymentOrders"
        ></v-text-field>

        <v-text-field
          label="Сумма"
          :rules="[v => validateTotalSum(v) || 'Заполните поле']"
          v-model="sumClient"
          v-if="partlyPayment || typePay === 4"
          @keyup="handleFirstSumChange"
          ref="firstSum"
        ></v-text-field>

        <v-select
          label="Способ оплаты"
          :items="typePayList"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="typePay"
        ></v-select>

        <v-checkbox
          label="Частичная"
          v-model="partlyPayment"
        />

        <v-select
          label="Второй способ оплаты"
          :items="typePayList"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="secondTypePay"
          v-if="partlyPayment"
        />

        <v-text-field
          label="Вторая сумма"
          :rules="[v => validateTotalSum(v) || 'Заполните поле']"
          v-model="secondSumClient"
          v-if="secondTypePay"
          ref="secondSum"
          @keyup="handleSecondSumChange"
        ></v-text-field>

        <v-text-field
          label="Сдача"
          readonly
          :value="sumChange"
          v-if="partlyPayment || typePay === 4"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="px-4 pb-4">
        <v-btn
          @click.native="$emit('cancel')"
          >Отмена</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submitForm" :loading="btnLoad"
          >Оплатить</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'BouquetMultiPayment',
  props: {
    items: {
      type: Array,
      required: true,
    },
    store_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      createdSuccess: false,
      btnLoad: false,

      sumClient: 0,
      typePay: '',
      secondSumClient: 0,
      secondTypePay: '',

      paymentTypesList: [],
      partlyPayment: false,
    };
  },
  computed: {
    typePayList() {
      const emptyClient = this.items.some(item => !item.props.clientId);

      // Filter the list based on the condition
      const filteredList = this.paymentTypesList.filter((item) => {
        if (emptyClient) {
          return item.id !== 1;
        }
        return true;
      });

      // Sort the filtered list by the 'sort' field in ascending order
      return filteredList.slice().sort((a, b) => a.sort - b.sort);
    },
    
    sumOrders() {
      return this.items.reduce((total, item) => {
        let sum = item.sum * +item.props.bouquetCount;

        sum += +item.props.decorCost;
        sum += +item.props.deliveryCost;
        sum += +item.props.sumDecorAdditional * +item.props.bouquetCount;
        sum -= +item.props.sumSale;
        return total + +sum.toFixed();
      }, 0);
    },
    sumPrePaymentOrders() {
      return this.items.reduce((total, item) => {
        return total + +item.props.prePayment;
      }, 0);
    },
    sumOrdersPay() {
      return this.sumOrders - this.sumPrePaymentOrders;
    },
    sumChange: function sumChange() {
      const sum = +this.sumClient + +this.secondSumClient - +this.sumOrdersPay;

      return sum > 0 ? sum : 0;
    },
  },
  methods: {
    validateTotalSum(v) {
      return +this.sumClient + +this.secondSumClient >= this.sumOrdersPay;
    },
    handleFirstSumChange() {
      this.$refs.secondSum && this.$refs.secondSum.validate();
    },
    handleSecondSumChange() {
      this.$refs.firstSum.validate();
    },
    getPaymentTypesList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;

      axios
        .get(url)
        .then((response) => {
          const items = response.data;
          this.paymentTypesList = items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        this.btnLoad = true;

        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquet-multi-payment`;
        console.log("item", this.items)
        const props = this.items.map((item) => {
          const itemParams = {
            bouquet_count: item.props.bouquetCount,
            client_id: item.props.clientId || 0,
            comment: item.props.comment,
            decor_cost: item.props.decorCost,
            decor_percent: item.props.decorPercent,
            delivery_comment: '',
            delivery_cost: item.props.deliveryCost,
            florist_id: item.props.floristId,
            order_id: item.props.orderId,
            sale_percent: item.props.salePercent,
            sale_sum: item.props.sumSale,
            sum_decor_additional: item.props.sumDecorAdditional,
            total_cost: item.props.totalCost / item.props.bouquetCount,
            goods: [],
            payment: {},
            second_payment: {},
            store_id: this.store_id,
          };

          if (item.goods.length) {
            item.goods.forEach((good) => {
              itemParams.goods.push({
                count: good.value,
                good_id: good.id,
              })
            });
          }

          let amount = 0;
          let secondAmount = 0;

          if (this.partlyPayment) {
            itemParams.payment.payment_type_id = this.typePay;
            itemParams.payment.comment = '';
            amount = (this.typePay === 'cash') ? +((+this.sumClient - +this.sumChange) / this.items.length).toFixed() : +(+this.sumClient / this.items.length).toFixed();

            itemParams.second_payment.payment_type_id = this.secondTypePay;
            itemParams.second_payment.comment = '';
            secondAmount = item.props.totalCost - ((this.typePay === 'cash') ? +((+this.sumClient - +this.sumChange) / this.items.length).toFixed() : +(+this.sumClient / this.items.length).toFixed());
          } else {
            itemParams.payment.payment_type_id = this.typePay;
            itemParams.payment.comment = '';
            amount = item.props.totalCost;

            itemParams.second_payment = null;
          }

          itemParams.payment.amount = amount / item.props.bouquetCount;

          if (itemParams.second_payment) {
            itemParams.second_payment.amount = secondAmount / item.props.bouquetCount;
          }

          return itemParams;
        });

        console.log(props);

        axios
          .post(url, props)
          .then(() => {
            setTimeout(() => {
              this.$emit('saveProps', props);
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.getPaymentTypesList();
  },
};
</script>
