<template>
  <div ref="statPayBlock" class="statPayBlock">
    <template v-for="(item, key) in paymentTypesSum">
      <template v-if="item.id === 4">
        <v-layout row wrap align-center :key="`cash-${key}`">
          <v-flex xs7>
            <b>В кассе:</b>
          </v-flex>
          <v-flex xs5>
            {{ cashNow  }}
          </v-flex>
        </v-layout>
      </template>

      <v-layout row wrap align-center :key="key">
        <v-flex xs7>
          <b>{{ item.name }}:</b>
        </v-flex>
        <v-flex xs5>
          {{ item.sum }}
        </v-flex>
      </v-layout>
    </template>

    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Сумма наличка:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumPayCash }}
      </v-flex>
    </v-layout> -->
    <!-- <template v-for="(item, key) in walletsList">
      <v-layout row wrap align-center :key="key">
        <v-flex xs7>
          <b>{{ item.name }}:</b>
        </v-flex>
        <v-flex xs5>
          {{ item.balance }}
        </v-flex>
      </v-layout>
    </template> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Терминал:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumPayTerminal }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Карта:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumPayCard }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Яндекс:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumPayYandex }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Сумма возвратов:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumReturn }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Инкасация:</b>
      </v-flex>
      <v-flex xs5>
        {{ allSumEncashment }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Терминал ЮГ-2:</b>
      </v-flex>
      <v-flex xs5>
        {{ terminalUg2 }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Тинькофф:</b>
      </v-flex>
      <v-flex xs5>
        {{ tinkoff }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Газпром:</b>
      </v-flex>
      <v-flex xs5>
        {{ gazprom }}
      </v-flex>
    </v-layout> -->
    <!-- <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Расход:</b>
      </v-flex>
      <v-flex xs5>
        {{ expenses }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs7>
        <b>Сейчас в кассе:</b>
      </v-flex>
      <v-flex xs5>
        {{ cashNow }}
      </v-flex>
    </v-layout> -->

    <v-btn color="info" small @click="dialog = true">Сдать инкассацию</v-btn>
    <v-btn color="info" small @click="dialogCloseDay = true">Закрыть день</v-btn>

    <v-dialog v-model="dialog" persistent max-width="420px">
      <v-card>
        <v-alert :value="createdSuccess" type="success" class="my-0">
          Инкассация сдана
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-card-title class="px-4">
            <span class="headline">Сдача инкассации</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-4">
            <v-text-field
              label="Сумма"
              :rules="[v => v <= cashNow || 'Недостаточно денег в кассе']"
              v-model.number="sumEncashment"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-btn @click.native="dialog = false">Отмена</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="info" @click="submit()">Оплатить</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCloseDay" persistent max-width="320px">
      <v-card>
        <v-card-title class="px-4">
          <span class="headline">Закрыть день?</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="px-4 py-3">
          <v-btn @click.native="dialogCloseDay = false">Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="closeDay()">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCloseOfDay" persistent max-width="520px">
      <template>
        <close-of-day
          :item="paymentTypesSum"
          @cancel="cancelCloseOfDay()"
        ></close-of-day>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import io from 'socket.io-client';
import closeOfDay from './closeOfDay.vue';

const startCurrentDate = new Date();
startCurrentDate.setHours(0, 0, 0, 0);

const endCurrentDate = new Date();
endCurrentDate.setHours(23, 59, 59, 999);

const startPrevDate = new Date();
startPrevDate.setDate(startPrevDate.getDate() - 1);
startPrevDate.setHours(0, 0, 0, 0);

const endPrevDate = new Date();
endPrevDate.setDate(endPrevDate.getDate() - 1);
endPrevDate.setHours(23, 59, 59, 999);

export default {
  components: {
    closeOfDay,
  },
  props: {
    upadate: {
      default: false,
      type: Boolean,
    },
    paymentTypesList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      dateNow: new Date(),
      dateYesterday: '',
      createdSuccess: false,
      dialog: false,
      dialogCloseDay: false,
      dialogCloseOfDay: false,
      sumEncashment: 0,
      queryValue: 0,

      allSumPayCashPrevDay: 0,
      allSumPayTerminal: 0,
      allSumPayCard: 0,
      allSumPayYandex: 0,
      allSumReturn: 0,
      allSumEncashmentPrevDay: 0,
      terminalUg2: 0,
      tinkoff: 0,
      gazprom: 0,
      walletsList: [],

      paymentsList: [],
      socket: null,
      update: false,
      collector_id: null,
    };
  },
  // created() {
  //   this.startRequestInterval();
  // },
  watch: {
    upadate(val) {
      if (val) {
        this.getPaymentsList();
      }
    },
    paymentsList: {
      handler: function(newVal, oldVal) {
        // Trigger recalculation of paymentTypesSum when paymentsList changes
        this.paymentTypesSum;
      },
      deep: true // Watch for changes deeply in the object/array
    }
  },
  computed: {
    paymentTypesSum() {
      return this.paymentTypesList.map((itemType) => {
        let sum = 0;
        const paymentsType = this.paymentsList.filter((item) => item.payment_type_id === itemType.id);

        if (paymentsType) {
          sum = paymentsType.reduce((total, item) => {
            if(item.is_refund){
              return total;
            } else {
              return total + item.amount;
            }
          }, 0);
        }
        return { sum: sum, ...itemType }
      });
    },
    cashNow() {
      return (
        // this.allSumPayCashPrevDay -
        // this.allSumEncashmentPrevDay +
        this.allSumPayCash -
        this.allSumEncashment -
        this.expenses
      );
    },
    allSumPayCash() {
      const findItem = this.paymentTypesSum.find((item) => item.id === 4);
      return (findItem) ? findItem.sum : 0;
    },
    allSumEncashment() {
      const findItem = this.paymentTypesSum.find((item) => item.id === 7);
      return (findItem) ? findItem.sum : 0;
    },
    expenses() {
      const findItem = this.paymentTypesSum.find((item) => item.id === 6);
      return (findItem) ? findItem.sum : 0;
    },
  },
  methods: {
    emitStatPayBlockHeight() {
      this.$nextTick(() => {
        const statPayBlock = this.$refs.statPayBlock;

        if (statPayBlock) {
          const height = statPayBlock.clientHeight;
          this.$emit('statPayBlockHeight', height);
        }
      });
    },
    calculatePaymentTypesSum() {
      return this.paymentTypesList.map((itemType) => {
        let sum = 0;
        const paymentsType = this.paymentsList.filter((item) => item.payment_type_id === itemType.id);

        if (paymentsType) {
          sum = paymentsType.reduce((total, item) => {
            if(item.is_refund){
              return total;
            } else {
              return total + item.amount;
            }
          }, 0);
        }
        return { sum: sum, ...itemType };
      });
    },
    // startRequestInterval() {
    //   this.interval = setInterval(() => {
    //     this.getPaymentsList();
    //   }, 2000); // 2 seconds
    // },
    getPaymentsList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;

      const startCurrentDate = new Date();
      startCurrentDate.setHours(0, 0, 0, 0);
      let dd = startCurrentDate.getDate();
      if (dd < 10) dd = '0' + dd;
      let mm = startCurrentDate.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      let yy = startCurrentDate.getFullYear();
      const startCurrentDateStr = `${yy}-${mm}-${dd}`;

      const endCurrentDate = new Date();
      endCurrentDate.setHours(0, 0, 0, 0);
      endCurrentDate.setDate(endCurrentDate.getDate() + 1);
      dd = endCurrentDate.getDate();
      if (dd < 10) dd = '0' + dd;
      mm = endCurrentDate.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      yy = endCurrentDate.getFullYear();
      const endCurrentDateStr = `${yy}-${mm}-${dd}`;


      const propsItem = {
        start_date: startCurrentDateStr,
        end_date: endCurrentDateStr,
        page_limit: 99999,
      };

      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.paymentsList = response.data;

          this.$emit('updateComplete', true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDay() {
      this.dialogCloseOfDay = true;
      this.dialogCloseDay = false;
    },
    cancelCloseOfDay(){
      this.dialogCloseOfDay = false;
    },
    getSettingRemote(id) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/setting-remote/${id}`;
      axios
          .get(url)
          .then((response) => {
            const item = response.data;
            this.collector_id = item.collector_id;
          })
          .catch((error) => {
          console.log(error);
          });
    },
    submit() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;
        if(!this.collector_id){
          alert("Please set collect_id from setting remote page")
          return
        }
        const props = {
          amount: this.sumEncashment,
          //client_id: this.collector_id, // collecter
          client_id: 1904,
          store_id: parseInt(localStorage.getItem('store-id')),
          decor_cost: 0,
          delivery_cost: 0,
          payment_type_id: 7,
          comment: '',
        };

        axios
          .post(url, props)
          .then(() => {
            setTimeout(() => {
              this.$emit("upadatePaymentsSum");
              this.dialog = false;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
  mounted() {
    this.getPaymentsList();
    this.emitStatPayBlockHeight();
    this.getSettingRemote(1); // get collector id from setting-remote table
  }
};
</script>

<style lang="scss">
.statPayBlock {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  width: 322px;
  padding: 9px 10px;
  border: 1px solid #ccc;
}
</style>
